<template>
  <header class="the-header d-flex justify-between align-center px-3 px-md-5">
    <div class="the-header__nav d-flex justify-start align-center">
      <router-link class="d-flex" :to="logoRouteTo" custom>
        <easy-care-logo />
      </router-link>
      <the-modules-nav-bar v-if="!isMobile && !isPartnerAccess" class="d-flex ml-5" />
    </div>
    <div class="the-header__actions flex-list-5 justify-end align-center">
      <v-fade-transition>
        <div v-show="getIsCpxCardReaderIconVisible">
          <card-reader-status-icon
            :type="CARD_TYPES.CPX"
            :inverted="isMobile"
            :color="isMobile ? 'text' : 'white'"
            @click="onCardReaderClick"
            @statusChange="onCardReaderStatusChanged"
          />
        </div>
      </v-fade-transition>
      <vitale-card-search-button :inverted="isMobile" />
      <template v-if="!isPartnerAccess">
        <n-divider vertical :spacing="0" class="the-header__actions__divider" />
        <search-menu-button />
        <n-divider vertical :spacing="0" class="the-header__actions__divider" />
        <intellio-next-button v-if="hasPermission('create_fse')" />
      </template>
      <user-dropdown />
    </div>
  </header>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { CARD_STATUS } from '@/services/card-reader/constants/cardStatus';
import { CARD_TYPES } from '@/services/card-reader/constants/cardTypes';
import { isPartnerAccess } from '@/services/partnerService';

import CardReaderStatusIcon from '@/components/ui/cardReader/CardReaderStatusIcon.vue';
import EasyCareLogo from '@/components/ui/easyCareLogo/EasyCareLogo.vue';
import IntellioNextButton from '@/layout/default/components/header/IntellioNextButton.vue';
import SearchMenuButton from '@/layout/default/components/header/searchMenu/SearchMenuButton.vue';
import UserDropdown from '@/layout/default/components/header/userDropdown/UserDropdown.vue';
import VitaleCardSearchButton from '@/layout/default/components/header/vitaleCardSearch/VitaleCardSearchButton.vue';
import TheModulesNavBar from '@/layout/default/components/modulesNavBar/TheModulesNavBar.vue';

/**
 * Header de l'application
 */
export default {
  name: 'TheHeader',
  components: {
    SearchMenuButton,
    IntellioNextButton,
    EasyCareLogo,
    UserDropdown,
    TheModulesNavBar,
    CardReaderStatusIcon,
    VitaleCardSearchButton,
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'getIsCpxCardReaderIconVisible']),
    ...mapGetters('auth', ['hasPermission']),
    isPartnerAccess () {
      return isPartnerAccess();
    },
    logoRouteTo () {
      return this.isPartnerAccess ? '/patients' : '/';
    },
    CARD_TYPES: () => CARD_TYPES,
  },
  methods: {
    ...mapMutations('app', ['SET_CPX_CARD_READER_ICON_VISIBLE']),
    ...mapMutations('cardReaders', ['SET_PINCODE_MODAL_VISIBLE']),
    onCardReaderClick (status) {
      if (status === CARD_STATUS.UNKNOWN_CONTENT) {
        this.SET_PINCODE_MODAL_VISIBLE(true);
      }
    },
    onCardReaderStatusChanged (status) {
      if (status === CARD_STATUS.CONTENT_READY) {
        setTimeout(() => {
          this.SET_CPX_CARD_READER_ICON_VISIBLE(false);
        }, 4000);
      } else {
        this.SET_CPX_CARD_READER_ICON_VISIBLE(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$header-height--desktop: 60px;
$header-height--mobile: 45px;

.the-header {
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  max-height: $header-height--mobile;
  max-width: 100vw;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;

  @include media-md('min') {
    background-color: var(--v-content-base);
    max-height: $header-height--desktop;
  }

  &__nav {
    width: 100%;
    height: 100%;
  }

  &__actions {
    width: 100%;
    height: 100%;

    &__divider {
      background-color: white;
      opacity: .12;
      align-self: center;
      min-height: 0;
      max-height: 24px;
    }
  }

  .card-reader-icon {
    display: flex;
    align-self: stretch;
  }
}
</style>